var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentUser.superAdmin)?_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.blogs,"options":_vm.pagination,"server-items-length":_vm.totalBlogs,"loading":_vm.loading,"footer-props":{
      showFirstLastPage: true,
      'items-per-page-options': [10, 20, 50, 100]
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.logo",fn:function(ref){
    var item = ref.item;
return [_c('a',{attrs:{"href":item.logo,"target":"_blank"}},[(item != null)?_c('v-img',{attrs:{"src":item.logo,"contain":"","max-height":"200","max-width":"200"}}):_vm._e()],1)]}},{key:"item.cities",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.cityNames != null && item.cityNames.length > 0 ? item.cityNames[0] : "")+" "),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [(item.cityNames != null && item.cityNames.length > 1)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v("mdi-dots-horizontal ")]):_vm._e()]}}],null,true)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"500px"},attrs:{"dense":""}},_vm._l((item.cityNames),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)]}},{key:"item.active",fn:function(ref){
    var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":""},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.image",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_c('v-img',{attrs:{"src":'data:' + item.imageContentType + ';base64,' + item.image,"contain":"","max-height":"100","max-width":"100","alt":"blogs image"}})],1)]}},{key:"item.clear",fn:function(ref){
    var item = ref.item;
return [(_vm.currentUser.superAdmin || (!_vm.currentUser.superAdmin && !item.allCities))?_c('v-avatar',{attrs:{"color":"teal","size":"26","rounded":""}},[_c('router-link',{attrs:{"to":{
            name: 'SponsorEdit',
            params: { sponsorId: item.id }
          }}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)],1):_vm._e()]}},{key:"item.add",fn:function(ref){
          var item = ref.item;
return [(_vm.currentUser.superAdmin || (!_vm.currentUser.superAdmin && !item.allCities))?_c('v-avatar',{attrs:{"color":"red darken-4","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.delRecord(item)}}},[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-avatar',{attrs:{"color":"indigo","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                _vm.retrieveBlogs();}}},[_vm._v("mdi-magnify ")])],1)],1),_c('td',[_c('v-avatar',{attrs:{"color":"grey","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                _vm.clearSearch();}}},[_vm._v("mdi-close ")])],1)],1),_c('td',[_c('router-link',{attrs:{"to":{ name: 'SponsorCreate' }}},[_c('v-avatar',{attrs:{"color":"teal","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1)],1)])]},proxy:true}],null,false,629297274)}),_c('ConfirmDialog',{ref:"confirm"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }