<template>
  <div v-if="currentUser.superAdmin">
    <v-data-table
      :headers="headers"
      :items="blogs"
      :options.sync="pagination"
      :server-items-length="totalBlogs"
      :loading="loading"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
      class="elevation-1"
    >
      <template v-slot:item.logo="{ item }">
        <a v-bind:href="item.logo" target="_blank">
          <v-img v-if="item != null" :src="item.logo" contain max-height="200" max-width="200">
          </v-img>
        </a>
      </template>

      <template v-slot:item.cities="{ item }">
        {{ item.cityNames != null && item.cityNames.length > 0 ? item.cityNames[0] : "" }}
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon
              v-if="item.cityNames != null && item.cityNames.length > 1"
              small
              class="mr-2"
              v-on="on"
              >mdi-dots-horizontal
            </v-icon>
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item v-for="(item, i) in item.cityNames" :key="i">
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.active="{ item }">
        <v-switch v-model="item.active" disabled></v-switch>
      </template>
      <template v-slot:item.image="{ item }">
        <td class="text-xs-right">
          <v-img
            v-bind:src="'data:' + item.imageContentType + ';base64,' + item.image"
            contain
            max-height="100"
            max-width="100"
            alt="blogs image"
          />
        </td>
      </template>
      <template v-slot:item.clear="{ item }">
        <v-avatar
          v-if="currentUser.superAdmin || (!currentUser.superAdmin && !item.allCities)"
          color="teal"
          size="26"
          rounded
        >
          <router-link
            :to="{
              name: 'SponsorEdit',
              params: { sponsorId: item.id }
            }"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </router-link>
        </v-avatar>
      </template>
      <template v-slot:item.add="{ item }">
        <v-avatar
          v-if="currentUser.superAdmin || (!currentUser.superAdmin && !item.allCities)"
          color="red darken-4"
          size="26"
          rounded
        >
          <v-icon dark @click="delRecord(item)">mdi-delete</v-icon>
        </v-avatar>
      </template>
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>

          <td>
            <v-avatar color="indigo" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  retrieveBlogs();
                "
                >mdi-magnify
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <v-avatar color="grey" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  clearSearch();
                "
                >mdi-close
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <router-link :to="{ name: 'SponsorCreate' }">
              <v-avatar color="teal" size="26" rounded>
                <v-icon dark>mdi-plus</v-icon>
              </v-avatar>
            </router-link>
          </td>
        </tr>
      </template>
    </v-data-table>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import { mapGetters } from "vuex";

export default {
  name: "sponsors",
  computed: {
    ...mapGetters(["currentUser"]),
    headers() {
      return [
        { text: "Id", align: "start", sortable: true, value: "id" },
        { text: this.$i18n.t("common.title"), value: "title" },
        { text: this.$i18n.t("common.logo"), value: "logo" },
        {
          text: this.$i18n.t("pages.shopPosts.city"),
          value: "cities",
          width: "10%",
          sortable: false
        },
        { text: this.$i18n.t("pages.sectors.type"), value: "type" },
        { text: this.$i18n.t("pages.sectors.active"), value: "active" },
        { text: "", value: "search", width: "5%" },
        { text: "", value: "clear", width: "5%" },
        { text: "", value: "add", width: "5%" }
      ];
    }
  },
  data() {
    return {
      blogTitle: "",
      totalBlogs: 0,
      blogs: [],
      loading: true,
      pagination: {},
      citiesList: [],
      selectedCities: [],
      dialogDelete: false,
      itemToDelete: null,
      errors: []
    };
  },
  watch: {
    pagination: {
      handler() {
        this.retrieveBlogs();
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Sponsored By" }]);
  },
  components: {
    ConfirmDialog
  },
  methods: {
    async delRecord(item) {
      if (
        await this.$refs.confirm.open(
          this.$i18n.t("common.confirm"),
          this.$i18n.t("pages.sponsoredBy.deleteMessage") + item.id + " ?"
        )
      ) {
        this.deleteRecord(item);
      }
    },
    deleteRecord(item) {
      this.deleteBlog(item.id);
    },
    updateSelectedCities(selectedCities) {
      this.selectedCities = selectedCities;
    },
    deleteBlog(blogId) {
      this.loading = true;
      ApiService.delete(`api/blog/${blogId}`)
        .then(response => {
          this.$log.debug("Sponsor deleted: ", response);
          this.retrieveBlogs();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    getRequestParams(blogTitle) {
      let params = {};

      if (blogTitle) {
        params["blogTitle"] = blogTitle;
      }

      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ",desc";
      }
      params["sort"] = sort;
      params["page"] = this.pagination.page;
      params["size"] = this.pagination.itemsPerPage;

      return params;
    },
    clearSearch() {
      this.blogTitle = "";
    },
    retrieveBlogs() {
      const params = this.getRequestParams(this.blogTitle);
      return new Promise(resolve => {
        this.loading = true;
        ApiService.query("api/blog", {
          params
        }).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("Sponsor: ", response.data.content);
          this.blogs = response.data.content;
          this.totalBlogs = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
          let title = this.totalBlogs != null ? this.totalBlogs : 0;
          this.$store.dispatch(SET_BREADCRUMB, [{ title: "Sponsored By: " + title }]);
          resolve();
        });
      });
    }
  }
};
</script>

<style>
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}

.v-icon.v-icon {
  font-size: 24px !important;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}
</style>
